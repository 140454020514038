$( document ).ready( function () {
	$( 'form' ).on( 'submit', function ( e ) {
		e.preventDefault();
		const data = $( this ).serialize();
		$.ajax( {
			type: 'POST',
			url: 'https://subs.sonymusicfans.com/submit',
			dataType: 'json',
			data: data,
			xhrFields: {
				withCredentials: false
			},
			success: function ( data ) {
				$( 'form' ).html( 'Thanks for signing up!' );
			},
			error: function ( err ) {
				alert( 'An error has occured!' );
			}
		} );
	} );

	$( '.tour-link' ).on( 'click', function ( e ) {
		e.preventDefault();
		$( '#events' ).fadeToggle();
	} );

} );